<template>
  <div>
    <modal :borderInfo="'2px solid #1b6eba'" :show.sync="isOpen" size="lg">
      <template slot="header">
        <div style="display: flex; align-items: center">
          <img
            style="display: inline-flex"
            src="/img/icons/icons8/ios/exchange-dollar.png"
            width="22"
          />
          <h5
            style="display: inline-flex"
            class="modal-title p-0 m-0 ml-3 text-white"
          >
            {{ title }}
          </h5>
        </div>
      </template>

      <FinancialApprovalForm
        :formData="formData"
        @onCancelClick="closeModal"
        @onSubmitClick="handleSubmitForm"
      />
    </modal>
  </div>
</template>

<script setup>
//#region Imports
import { reactive, ref } from "vue";
import { initFinancialApprovalType, FinancialApprovalType } from "../../types";
import { dialogs, progress } from "../../../../../../helpers";
import FinancialApprovalForm from "../shared/FinancialApprovalForm.vue";
import financialApprovalStore from "../../store/financialApprovalStore";
//#endregion

//#region Data
const isOpen = ref(false);
const title = "Local de estoque";
/** @type {FinancialApprovalType} */
const formData = reactive(initFinancialApprovalType());
//#endregion

//#region Methods
/** Abrir modal */
function openModal() {
  setFormData();
  isOpen.value = true;
}

/** Fechar modal */
function closeModal() {
  isOpen.value = false;
}

/** Resetar dados do formulário */
function setFormData() {
  Object.assign(formData, initFinancialApprovalType());
}

/** Criar registro */
function handleSubmitForm() {
  progress.start();
  const loader = progress.showLoader();
  financialApprovalStore.actions.create(formData)
    .then(() => {
      dialogs.notify();
      closeModal();
    })
    .finally(() => {
      progress.finish();
      loader.hide();
    });
}
//#endregion

//#region DefineExposes
/**
 * @typedef {Object} FinancialApprovalCreateModalExpose
 * @property {typeof openModal} openModal
 */
defineExpose({
  openModal,
});
//#endregion
</script>

<style scoped></style>
