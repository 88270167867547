import FinancialApprovalListPage from "../views/list/FinancialApprovalListPage";

export default [
  {
    path: '/financial/approvals',
    name: 'financial.approval.view',
    component: FinancialApprovalListPage,
    meta: {
      title: 'Financeiro',
      description: 'Aprovação de Antecipação',
      icon: 'fa fa-home',
      permission: 'configuration.plant.index.view',
      breadcrumbs: [
        {title: 'Dashboard', name: 'financial.approval.index'},
        {title: 'Aprovação de Antecipação', name: null},
      ]
    },
  },
];
