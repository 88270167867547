import { BaseListFilterType, initBaseListFilterType, RangeDateType, RangeValueType } from "../../../../../shared/types";

/**
 * Type para filtro de listagem (Extensão)
 *
 * @typedef {Object} FinancialApprovalListFilterExtType
 * @property {boolean|null} status
 * @property {boolean|null} pending_card_integration
 * @property {boolean|null} is_nsu_code_duplicated
 * @property {RangeDateType|null} range - Intervalo entre datas
 * @property {RangeValueType|null} range_value
 * @property {RangeDateType|null} range_document_number - Intervalo entre datas
 * @property {?number} company_plant_id
 */

/**
 * Type para filtro de listagem
 *
 * @typedef {(BaseListFilterType & FinancialApprovalListFilterExtType)} FinancialApprovalListFilterType
 */
export const FinancialApprovalListFilterType = {};

/**
 * Inicializar FinancialApprovalListFilterType
 *
 * @returns {FinancialApprovalListFilterType}
 */
export const initFinancialApprovalListFilterType = () => {
  return {
    ...initBaseListFilterType(),
    status: null,
    type: 1,
    bank_accounts: [],
    pending_card_integration: false,
    range_document_number: {
      min: null,
      max: null
    },
    range_value: {
      min: null,
      max: null
    },
    is_nsu_code_duplicated: false,
    range: {
      start: null,
      end: null,
    },
    company_plant_id: null,
    orderBy: '',
  };

}