const keys = Object.freeze({
  RECEIVED_AND_APPROVED: 8,
  DENIED: 9,
});

const getTitle = (value) => {
  switch (value) {
    case keys.RECEIVED_AND_APPROVED:
      return "Recebido e aprovado";
    case keys.DENIED:
      return "Negado";
    default:
      return "";
  }
};

export default {
  keys,
  getTitle,
};
