<template>
  <div>
    <AppFixedPageTitle title="Aprovação de antecipação" icon="/img/icons/icons8/ios/duration-finance.png" />
    <AppPageHeader>
      <template slot="search-bar">
        <AppSearchBar :searchBarFilter.sync="searchBarFilter" :isLoading="$_financial_approval_is_listing"
          @onSearchClick="listItems" @onClearClick="clearFilter" :showCompanyPlants="true">
          <AppSearchBarFilterSection name="status" icon="/img/icons/icons8/ios/progress-indicator_grey.png">
            <div class="col-md-12 mt-1 mb-2 px-0 text-left">
              <label class="form-control-label fs-11 new-default-gray-font font-weight-400">
                Selecione
              </label>
              <PuzlSelect style="width: 100%" v-model.lazy="filter.status" :items="[
                { id: null, name: 'Todos' },
                { id: 8, name: 'Recebido e aprovado' },
                { id: 9, name: 'Negado' },
              ]" :disableBoxShadow="true" placeholder="Status"
                class="select-sm col-md-12 px-0 new-default-black-font" />
            </div>
          </AppSearchBarFilterSection>
          <AppSearchBarFilterSection name="dados" icon="/img/icons/icons8/ios/info-squared_gray.png">
            <div class="col-md-12 mt-1 mb-2 px-0 text-left">
              <label class="form-control-label fs-11 new-default-gray-font font-weight-400">
                Selecione
              </label>
              <PuzlSelect style="width: 100%" v-model.lazy="filter.bank_accounts" :items="bankAccounts"
                :disableBoxShadow="true" placeholder="Status" :multiple="true"
                class="select-sm col-md-12 px-0 new-default-black-font" />
            </div>
            <div class="mt-2 w-100">
              <div class="text-left">
                <label class="form-control-label">
                  Valor do pagamento
                </label>
                <div class="row">
                  <div class="col-6 text-left pr-1">
                    <base-input inputmode="numeric" v-model="filter.range_value.min" v-money="money"
                      input-group-classes="input-group-sm" placeholder="de" size="sm">
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0">
                          R$
                        </small>
                      </template>
                    </base-input>
                  </div>
                  <div class="col-6 pl-1">
                    <base-input v-money="money" inputmode="numeric" input-group-classes="input-group-sm"
                      placeholder="de" v-model="filter.range_value.max" size="sm">
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0">
                          R$
                        </small>
                      </template>
                    </base-input>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-2 w-100">
              <div class="text-left">
                <label class="form-control-label">
                  NÚMERO DO DOCUMENTO
                </label>
                <div class="row">
                  <div class="col-6 text-left pr-1">
                    <base-input inputmode="numeric" v-model="filter.range_document_number.min"
                      input-group-classes="input-group-sm" placeholder="de" size="sm">
                    </base-input>
                  </div>
                  <div class="col-6 pl-1">
                    <base-input inputmode="numeric" v-model="filter.range_document_number.max"
                      input-group-classes="input-group-sm" placeholder="até" size="sm">
                    </base-input>
                  </div>
                </div>
              </div>
            </div>
          </AppSearchBarFilterSection>
          <AppSearchBarFilterSection name="Marcadores" icon="/img/icons/icons8/ios/push-pin_gray.png">
            <div class="col-12 px-0 mt-1">
              <BaseButtonHoverable :active="filter.is_nsu_code_duplicated"
                @click="filter.is_nsu_code_duplicated = !filter.is_nsu_code_duplicated" size="sm" type="danger"
                icon="break--v2" platform="ios" style="height: 32px;">
                Verificar integração
              </BaseButtonHoverable>
            </div>
            <div class="col-12 px-0 mt-2 mb-1">
              <BaseButtonHoverable :active="filter.pending_card_integration"
                @click="filter.pending_card_integration = !filter.pending_card_integration" size="sm" type="dark"
                icon="api" platform="ios" style="height: 32px;">
                Duplicidade de código
              </BaseButtonHoverable>
            </div>
          </AppSearchBarFilterSection>
        </AppSearchBar>
      </template>
    </AppPageHeader>

    <AppTabSelect :items="tabSelectItems" :isShowViewTypes="false" @onTabSelectItemClick="onTabSelectItemClick"
      @onViewTypeChange="(type) => listType = type">
      <AppSelect v-model="orderBy.selected" :items.sync="orderBy.items" @onSelectItemClick="listItems(false)"
        variant="text-only" />
    </AppTabSelect>
    <div class="container-fluid mt-4">
      <FinancialApprovalListPageCards @onEditItemClick="listItems" />
      <AppViewTrigger v-if="!$_financial_approval_is_listing" @onIntersected="listItems(true)" />
      <PuzlEmptyData v-if="!$_financial_approval_is_listing && !$_financial_approval_listed.items.length" />
    </div>
    <FinancialApprovalCreateModal ref="financialApprovalCreateModal" />
  </div>
</template>

<script setup>
//#region Imports
import { reactive, ref, computed, onMounted, onUnmounted } from 'vue';
import {
  AppFixedPageTitle,
  AppPageHeader,
  initSearchBarFilterType,
  SearchBarFilterType,
  AppSearchBar,
  AppPageHeaderActions,
  AppPageHeaderActionsButton,
  AppViewTrigger,
  AppTabSelect,
  AppSelect,
  TabSelectItemType,
  AppSearchBarFilterSection,
} from '../../../../../../components/AppGlobal';
import financialApprovalStore from "../../store/financialApprovalStore";
import { initFinancialApprovalListFilterType, FinancialApprovalListFilterType } from '../../types';
import { dialogs, date, moneyToFloat, strToNum } from '../../../../../../helpers';
import FinancialApprovalListPageCards from './FinancialApprovalListPageCards.vue';
import PuzlEmptyData from "@/components/PuzlEmptyData";
import FinancialApprovalCreateModal from "../create/FinancialApprovalCreateModal.vue";
import FinancialApprovalTypeFilterStatusEnum from "../../enums/FinancialApprovalTypeFilterStatusEnum";
import PuzlSelect from "@/components/PuzlSelect";
import BaseButtonHoverable from "@/components/Utils/BaseButtonHoverable.vue";
import bankAccountStore from "../.././../../../../../src/modules/configuration/bank-account/store/bankAccountStore";
//#endregion

//#region ComponentRefs
/** @type {Ref<FinancialApprovalCreateModalExpose>} */
const financialApprovalCreateModal = ref(null);
//#endregion

//#region Data
/** @type {SearchBarFilterType} - Filtro do AppSearchBar */
const searchBarFilter = reactive(initSearchBarFilterType());

/** @type {FinancialApprovalListFilterType} - Filtro principal */
const filter = reactive(initFinancialApprovalListFilterType());

const currentDate = date.make().format(date.FORMAT.ISO_8601);

const listType = ref('cards');

const bankAccounts = ref([]);

let money = {
  decimal: ",",
  thousands: ".",
  prefix: "",
  suffix: "",
  precision: 2,
  masked: false
}

const orderBy = reactive({
  items: [
    {
      id: 0,
      name: "PADRÃO",
      selected_name: "ORDENAR",
      filter: 'created.asc',
    },
    {
      id: 1,
      name: "DO MAIS NOVO PARA O MAIS VELHO",
      selected_name: "MAIS NOVO",
      icon: "/img/icons/icons8/ios/double-up.png",
      filter: 'created.desc',
    },
    {
      id: 2,
      name: "DO MAIS VELHO PARA O MAIS NOVO",
      selected_name: "MAIS VELHO",
      icon: "/img/icons/icons8/ios/double-down.png",
      filter: 'date_at.asc',
    },
    {
      id: 3,
      name: "DATA DE COMPETÊNCIA: DA MAIS NOVA PARA A MAIS VELHA",
      selected_name: "COMPETÊNCIA NOVA",
      icon: "/img/icons/icons8/ios/double-up.png",
      filter: 'value.desc',
    },
    {
      id: 4,
      name: "DATA DE COMPETÊNCIA: DA MAIS VELHA PARA A MAIS NOVA",
      selected_name: "COMPETÊNCIA VELHA",
      icon: "/img/icons/icons8/ios/double-down.png",
      filter: 'value.asc',
    },
    {
      id: 5,
      name: "VALOR: DO MAIOR PARA O MENOR",
      selected_name: "VALOR MAIOR",
      icon: "/img/icons/icons8/ios/double-up.png",
      filter: 'value.desc',
    },
    {
      id: 6,
      name: "VALOR: DO MENOS PARA O MAIOR",
      selected_name: "VALOR MENOR",
      icon: "/img/icons/icons8/ios/double-down.png",
      filter: 'value.asc',
    },
  ],
  selected: 0,
});
//#endregion

//#region Computeds
/** Store Getters */
const $_financial_approval_listed = computed(() => financialApprovalStore.getters.getListed());
const $_financial_approval_is_listing = computed(() => financialApprovalStore.getters.getIsListing());

const tabSelectItems = computed(() => {
  return [
    {
      id: null,
      name: 'Todos',
      selected: filter.status === null,
    },
    {
      id: FinancialApprovalTypeFilterStatusEnum.keys.RECEIVED_AND_APPROVED,
      name: FinancialApprovalTypeFilterStatusEnum.getTitle(FinancialApprovalTypeFilterStatusEnum.keys.RECEIVED_AND_APPROVED),
      selected: filter.status === FinancialApprovalTypeFilterStatusEnum.keys.RECEIVED_AND_APPROVED,
    },
    {
      id: FinancialApprovalTypeFilterStatusEnum.keys.DENIED,
      name: FinancialApprovalTypeFilterStatusEnum.getTitle(FinancialApprovalTypeFilterStatusEnum.keys.DENIED),
      selected: filter.status === FinancialApprovalTypeFilterStatusEnum.keys.DENIED,
    },
  ];
});

const loadBankAccounts = async () => {
  bankAccounts.value = await financialApprovalStore.actions.getBankAccounts();
};

//#endregion

//#region Methods
/**
 * @param {TabSelectItemType} item
 */
function onTabSelectItemClick(item) {
  if (filter.status !== item.id) {
    filter.status = item.id;
    listItems();
  };
};

/**
 * Limpa os filtros e atualiza a lista de itens.
 *
 * @param {boolean} isRefreshList - Atualiza a lista após limpar os filtros.
 * @param {boolean} withStoreFilters - Usa filtros da store se verdadeiro.
 */
function clearFilter(isRefreshList = true, withStoreFilters = false) {
  // Inicializa filtros com os valores padrão
  let searchBarFilterValue = defaultSearchBarFilter();
  let filterValue = defaultFilter();

  // Caso `withStoreFilters` seja verdadeiro, obter filtros armazenados na store
  const storeFilters = withStoreFilters ? financialApprovalStore.getters.getPageState() : null;
  if (storeFilters) {
    searchBarFilterValue = storeFilters.searchBarFilter;
    filterValue = storeFilters.filter;
  }

  // Aplica os filtros
  Object.assign(searchBarFilter, searchBarFilterValue);
  Object.assign(filter, filterValue);

  // Listar itens
  if (isRefreshList) {
    listItems();
  }
};



/**
 * Padrão do filtro AppSearchBar
 * @returns {SearchBarFilterType}
 */
function defaultSearchBarFilter() {
  return {
    ...initSearchBarFilterType(),
    range: {
      items: [],
      selected: null,
      start: filter.range.start ?? currentDate,
      end: filter.range.end ?? currentDate,
    }
  }
};

/**
 * Padrão do filtro principal
 * @returns {FinancialApprovalListFilterType}
 */
function defaultFilter() {
  return initFinancialApprovalListFilterType();
};

/**
 * Listar itens
 * @param {boolean} isAccumulateItems
 */
function listItems(isAccumulateItems = false) {
  prepareFilter();
  financialApprovalStore.actions.list(filter, isAccumulateItems);
};

/**
 * Preparar filtro principal para listagem
 */
function prepareFilter() {
  filter.page = "";
  filter.custom_search.values = searchBarFilter.custom_search_values;
  filter.range = searchBarFilter.range;
  filter.company_plant_id = searchBarFilter.company_plant_selected;
  filter.orderBy = orderBy.items[orderBy.selected].filter;
  filter.range_value.min = strToNum(filter.range_value.min) > 0 ? moneyToFloat(filter.range_value.min) : null;
  filter.range_value.max = strToNum(filter.range_value.max) > 0 ? moneyToFloat(filter.range_value.max) : null;
};
//#endregion

//#region Lifecycle
onMounted(() => {
  clearFilter(true, true);
  loadBankAccounts();
});

onUnmounted(() => {
  searchBarFilter.custom_search_values = [];
  financialApprovalStore.mutations.resetStates();
  financialApprovalStore.mutations.setPageState({ filter, searchBarFilter });
});
//#endregion
</script>

<style scoped></style>
