import store from "../../../../../shared/libraries/store";
import { initFinancialApprovalListPagerType, FinancialApprovalListFilterType, FinancialApprovalListPagerType, FinancialApprovalType } from "../types";
import { createSource, findSource, listSources, removeSource, updateSource } from "../../../../../shared/store";
import httpClient from "../../../../../shared/libraries/httpClient";
import { HttpStatusEnum } from "../../../../../shared/enums";
import { handleUnexpectedStatusCode } from "../../../../../shared/services";

/** URLS */
const urls = Object.freeze({
  BASE: "/financial/approval",
});

/** KEYS */
const keys = Object.freeze({
  ITEM: "FINANCIAL_APPROVAL_ITEM",
  LISTED: "FINANCIAL_APPROVAL_LISTED",
  IS_LISTING: "FINANCIAL_APPROVAL_IS_LISTING",
  IS_FINDING: "FINANCIAL_APPROVAL_IS_FINDING",
  IS_SAVING: "FINANCIAL_APPROVAL_IS_SAVING",
  IS_REMOVING: "FINANCIAL_APPROVAL_IS_REMOVING",
  PAGE_STATES: "FINANCIAL_APPROVAL_PAGE_STATE",
});

/** GETTERS */
const getters = {
  /** @returns {?FinancialApprovalType} */
  getItem: () => store.getState(keys.ITEM),

  /** @returns {FinancialApprovalListPagerType} */
  getListed: () => store.getState(keys.LISTED) ?? initFinancialApprovalListPagerType(),

  /** @returns {boolean} */
  getIsListing: () => store.getState(keys.IS_LISTING) ?? false,

  /** @returns {boolean} */
  getIsFinding: () => store.getState(keys.IS_FINDING) ?? false,

  /** @returns {boolean} */
  getIsSaving: () => store.getState(keys.IS_SAVING) ?? false,

  /** @returns {boolean} */
  getIsRemoving: () => store.getState(keys.IS_REMOVING) ?? false,

  /** @returns {{  searchBarFilter: SearchBarFilterType, filter: FinancialApprovalListFilterType }} */
  getPageState: () => store.getState(keys.PAGE_STATES),
}

/** MUTATIONS */
const mutations = {
  /**
   * @param {FinancialApprovalType} item
   * @returns {FinancialApprovalType}
   */
  setItem: (item) => store.commit(keys.ITEM, item),

  /**
   * @param {FinancialApprovalListPagerType} listed
   * @returns {FinancialApprovalListPagerType}
   */
  setListed: (listed) => store.commit(keys.LISTED, listed),

  /**
   * @param {boolean} isListing
   * @returns {boolean}
   */
  setIsListing: (isListing) => store.commit(keys.IS_LISTING, isListing),

  /**
   * @param {boolean} isFinding
   * @returns {boolean}
   */
  setIsFinding: (isFinding) => store.commit(keys.IS_FINDING, isFinding),

  /**
   * @param {boolean} isSaving
   * @returns {boolean}
   */
  setIsSaving: (isSaving) => store.commit(keys.IS_SAVING, isSaving),

  /**
   * @param {boolean} isRemoving
   * @returns {boolean}
   */
  setIsRemoving: (isRemoving) => store.commit(keys.IS_REMOVING, isRemoving),

  /**
   * @param {FinancialApprovalType} created
   * @returns {FinancialApprovalType}
   */
  setNewItemInList: (created) => store.commitNewItemToList(keys, created),

  /**
   * @param {FinancialApprovalListPagerType} listed
   * @returns {FinancialApprovalListPagerType}
   */
  setListMerge: (listed) => store.commitListMerge(keys, listed),

  /**
   * @param {number} id
   */
  setRemovedItem: (id) => store.commitRemovedItem(keys, id),

  /**
   * @param {FinancialApprovalType} updated
   * @returns {FinancialApprovalType}
   */
  setUpdatedItem: (updated) => store.commitUpdatedItem(keys, updated),

  /** @param {{ searchBarFilter: SearchBarFilterType, filter: FinancialApprovalListFilterType }} filters */
  setPageState: (filters) => store.commit(keys.PAGE_STATES, filters),

  /** Resetar chaves de estado */
  resetStates: () => store.resetStates(Object.values(keys)),
}

/** ACTIONS */
const actions = {
  /**
   * Incluir
   *
   * @param {FinancialApprovalType} data
   * @returns {Promise<FinancialApprovalType>}
   */
  create: async (data) => await createSource(data, mutations, urls.BASE),

  /**
   * Localizar por ID
   *
   * @param {number} id
   * @returns {Promise<?FinancialApprovalType>}
   */
  find: async (id) => await findSource(id, mutations, urls.BASE),

  /**
   * Listar
   *
   * @param {FinancialApprovalListFilterType} filter - Filtro de listagem
   * @param {boolean} isAccumulateItems - Incluir mais itens na lista existente
   * @returns {Promise<?FinancialApprovalListPagerType>} - Retorno paginado
   */
  list: async (filter, isAccumulateItems = false) => await listSources(filter, isAccumulateItems, mutations, getters, urls.BASE),

  /**
   * Deletar por ID
   *
   * @param {number} id
   * @returns {Promise<void>}
   */
  remove: async (id) => await removeSource(id, mutations, urls.BASE),

  /**
   * Atualizar por ID
   *
   * @param {FinancialApprovalType} data
   * @param {number} id
   * @returns {Promise<FinancialApprovalType>}
   */
  update: async (data, id) => await updateSource(data, id, mutations, urls.BASE),

  /**
   * Atualizar status de aprovação por ID
   *
   * @param {number} id
   * @param {number} status
   * @returns {Promise<void>}
   */
  updateStatus: async (id, status) => {
    const text = status === 9 ? 'Negar' : 'Aprovar';
    try {
      mutations.setIsSaving(true);

      // Adiciona os parâmetros de query
      const url = `/financial/bill-receive/update-installment/${id}/type/0/status/${status}`;
      const queryParams = new URLSearchParams({
        id,
        status,
        type: 0,
      }).toString();

      const fullUrl = `${url}?${queryParams}`;
      // Realiza a requisição
      const res = await httpClient.get(fullUrl);

      if (res.status !== HttpStatusEnum.HTTP_OK) {
        handleUnexpectedStatusCode(res);
        return;
      }
      const updatedInstallment = res.data.data;
      mutations.setUpdatedItem(updatedInstallment);

      this.init({});
    } catch (error) {
      const errorMessage = error.response?.data?.message || "Erro inesperado.";
      console.error(errorMessage);
    } finally {
      mutations.setIsSaving(false);
    }
  },

  /**
   * Obter anexos por ID
   *
   * @param {number} id
   */
  getAttachments: async (id) => {
    try {
      const url = `/financial/bill-receive/attachments/${id}/type/0`;
      const res = await httpClient.get(url);

      if (res.status !== HttpStatusEnum.HTTP_OK && !res.error) {
        handleUnexpectedStatusCode(res);
        return;
      } else if (res.error) {
        console.error(errorMessage);
      }
      return res.data.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message || "Erro ao obter anexos.";
    }
  },

  getBankAccounts: async () => {
    try {
      const url = `/financial/bank-account?filter%5Bstatus%5D=true&allocated`;
      const res = await httpClient.get(url);

      if (res.status !== HttpStatusEnum.HTTP_OK) {
          handleUnexpectedStatusCode(res);
          return [];
      }
      return res.data.data;
    } catch (error) {
      return [];
    }
  }

}

export default {
  actions,
  getters,
  mutations,
};
